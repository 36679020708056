import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  id: "Layer_1",
  x: "0",
  y: "0",
  version: "1.1",
  viewBox: "0 0 56 58"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(".st1{fill:#fff}")
      ])),
      _: 1
    })),
    _cache[1] || (_cache[1] = _createStaticVNode("<linearGradient id=\"SVGID_1_\" x1=\"5.663\" x2=\"50.337\" y1=\"6.663\" y2=\"51.337\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" style=\"stop-color:#009fda;\"></stop><stop offset=\".125\" style=\"stop-color:#0080bf;\"></stop><stop offset=\".303\" style=\"stop-color:#005b9f;\"></stop><stop offset=\".482\" style=\"stop-color:#003e86;\"></stop><stop offset=\".658\" style=\"stop-color:#002a74;\"></stop><stop offset=\".832\" style=\"stop-color:#001d69;\"></stop><stop offset=\"1\" style=\"stop-color:#001965;\"></stop></linearGradient><path d=\"M51.2 49c0 2-1.6 3.6-3.6 3.6H8.4c-2 0-3.6-1.6-3.6-3.6V9c0-2 1.6-3.6 3.6-3.6h39.2c2 0 3.6 1.6 3.6 3.6z\" style=\"fill:url(#SVGID_1_);\"></path><path d=\"M43.9 33V16.1s-6.9-4-16.8-.1v17s9-4 16.8 0\" class=\"st1\"></path><g style=\"opacity:.8;\"><path d=\"M35.8 34.5V20\" style=\"fill:none;stroke:#001965;stroke-width:1.575;stroke-miterlimit:8;\"></path><path d=\"M35.8 15.3c.8 2.2 2.2 5 3.7 6.7l-3.7-1.4L32 22c1.5-1.7 2.9-4.5 3.8-6.7\" style=\"fill:#001965;\"></path></g><path d=\"M36.1 31.6c-4.6 0-8.9 1.6-8.9 2s2.9 1.5 6.5 1.5l-.2-.1c-9.4 11.5-11.3-7.3-11.3-7.3 6.8-7.6 3.7-10.8-.7-10.8-8.4.1-.9 10.4-.6 10.7 0 .3 1 10.3 5.2 11.9 5.4 2 8.8-4.2 9-4.2H37c-9.2 14.3-16.8 2.8-19.2-1.2 1.2-.9 1.9-2.3 1.9-4 0-2.7-2-4.7-4.4-4.7s-4.4 2.1-4.4 4.8 2 4.6 4.5 4.6c.4 0 .8 0 1.1-.1 1.2 1.8 5.3 9 11.3 9 5.9 0 9.8-7.1 10.8-8.4 3.1-.3 5.1-1.1 5.1-1.5.2-.4-3-2.2-7.6-2.2m-20.7 2.3c-2 0-3.7-1.8-3.7-3.8s1.6-3.7 3.7-3.7c2 0 3.5 1.8 3.5 3.8 0 2.1-1.5 3.7-3.5 3.7\" class=\"st1\"></path><path d=\"M16.2 30.2c0 .5-.4.9-.9.9s-.9-.4-.9-.9.4-.9.9-.9.9.4.9.9\" class=\"st1\"></path><path d=\"m15.3 29.7 1.6-2.4-1.1 2.9z\" class=\"st1\"></path>", 7))
  ]))
}
export default { render: render }