import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "Layer_1",
  "data-name": "Layer 1",
  viewBox: "0 0 64 68"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-1{fill:#fff;stroke:#e64a0e;stroke-miterlimit:10;stroke-width:.5px}.cls-4{fill:#e64a0e}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<path d=\"M59.4 47.15c0-9.3-12.27-16.85-27.4-16.85S4.6 37.85 4.6 47.15 16.87 64 32 64s27.4-7.54 27.4-16.85Z\" class=\"cls-1\"></path><path d=\"M15.12 34s-1.12 9 2 14.44h3.87l-2 1.68 13.53 12.3 12-12.37-1.65-1.4h3.84s3.81-7.37 1.87-14.65c0 0-12.58-9.23-33.46 0Z\" class=\"cls-1\"></path><path d=\"M21 31.67c.06.4 3.63 14.46 11.42 24.67 0 0 5.94-6.84 11-24.67a44 44 0 0 0-22.42 0\" style=\"fill:#e64a0e;opacity:.25;\"></path><path d=\"M21 31.67c.06.4 3.63 14.46 11.42 24.67 0 0 5.94-6.84 11-24.67a44 44 0 0 0-22.42 0Z\" style=\"stroke:#e64a0e;stroke-miterlimit:10;fill:none;stroke-width:.49px;\"></path><path d=\"m29.29 41.67 1.3 3.48h3.29l1.19-3.43z\" class=\"cls-4\"></path><path d=\"M34.07 44.78h-3.49l-1.03 7.16 2.9 4.32 2.86-4.3\" class=\"cls-4\"></path><path d=\"M49.07 23.31C49.07 12.65 41.43 4 32 4s-17.07 8.65-17.07 19.31S22.57 42.63 32 42.63 49.07 34 49.07 23.31Z\" style=\"stroke:#e64a0e;stroke-miterlimit:10;stroke-width:.5px;fill:#ffd3a4;\"></path><path d=\"M48.65 52.15h-2.57v1.71h-1.72v2.48h1.72v1.58h2.57v-1.58h1.72v-2.48h-1.72z\" class=\"cls-4\"></path>", 8))
  ]))
}
export default { render: render }