import backArrow from "@/components/icons/backArrow.svg?component";
import nextArrow from "@/components/icons/nextArrow.svg?component";
import moreInfo from "@/components/icons/moreInfo.svg?component";
import a from "@/components/icons/a.svg?component";
import b from "@/components/icons/b.svg?component";
import c from "@/components/icons/c.svg?component";
import amputation from "@/components/icons/amputation.svg?component";
import blindness from "@/components/icons/blindness.svg?component";
import doctor from "@/components/icons/doctor.svg?component";
import estimaterisk from "@/components/icons/estimaterisk.svg?component";
import heartattack from "@/components/icons/heartattack.svg?component";
import kidneyfailure from "@/components/icons/kidneyfailure.svg?component";
import email from "@/components/icons/email.svg?component";

import recommendations from "@/components/icons/recommendations.svg?component";
import riskfactor from "@/components/icons/riskfactor.svg?component";
import stroke from "@/components/icons/stroke.svg?component";
import welcomeLogo from "@/components/icons/welcomeLogo.svg?component";
import bpInfo from "@/components/icons/bpInfo.svg?component";
import cholestrolInfo from "@/components/icons/cholestrolInfo.svg?component";
import a1c from "@/components/icons/a1c.svg?component";
import hdl from "@/components/icons/hdl.svg?component";
import sbp from "@/components/icons/sbp.svg?component";
import tc from "@/components/icons/tc.svg?component";
import smoking from "@/components/icons/smoking.svg?component";
import interval from "@/components/icons/interval.svg?component";
import glucoseInfo from "@/components/icons/glucoseInfo.svg?component";
import scale from "@/components/icons/scale.svg?component";
import scale2 from "@/components/icons/scale2.svg?component";
import exercise from "@/components/icons/exercise.svg?component";
import weight from "@/components/icons/weight.svg?component";
import infoPop from "@/components/icons/infoPop.svg?component";
import infoPopped from "@/components/icons/infoPopped.svg?component";
import discuss from "@/components/icons/discuss.svg?component";
import cross from "@/components/icons/cross.svg?component";
import discussTitle from "@/components/icons/discussTitle.svg?component";
import report_logo from "@/components/icons/report_logo.svg?component";

import { h } from "vue";

const iconPack = {

  nextArrow,
  backArrow,
  moreInfo,
  a,
  b,
  c,
  amputation,
  blindness,
  doctor,
  estimaterisk,
  heartattack,
  kidneyfailure,
  email,
  recommendations,
  riskfactor,
  stroke,
  welcomeLogo,
  glucoseInfo,
  cholestrolInfo,
  bpInfo,
  a1c,
  hdl,
  sbp,
  tc,
  interval,
  smoking,
  scale,
  scale2,
  exercise,
  weight,
  infoPop,
  infoPopped,
  discuss,
  cross,
  discussTitle,
  report_logo
}

const novoIcons = {
  component: (props) => h(iconPack[props.icon]),
}

export { novoIcons }
