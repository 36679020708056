import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 46 60"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<linearGradient id=\"a\" x1=\"4.35\" x2=\"41.65\" y1=\"30\" y2=\"30\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" style=\"stop-color:#001965;\"></stop><stop offset=\".155\" style=\"stop-color:#001e69;\"></stop><stop offset=\".332\" style=\"stop-color:#002b75;\"></stop><stop offset=\".52\" style=\"stop-color:#004289;\"></stop><stop offset=\".715\" style=\"stop-color:#0062a5;\"></stop><stop offset=\".914\" style=\"stop-color:#008bc8;\"></stop><stop offset=\"1\" style=\"stop-color:#009fda;\"></stop></linearGradient><path fill=\"url(#a)\" d=\"M22.7 2.5s-41.2 54.9 0 54.9c42.7.1 0-54.9 0-54.9\"></path><g fill=\"#FFF\"><path d=\"M18.4 44.2H16l-.6-2h-3.2l-.6 2h-2l3.1-9.8h2.7zm-3.6-3.8-1-3.4-1 3.4zM19.4 36.2c1.6-.5 2.8-1.2 3.3-2h1.7v8.2h2.7v1.8h-8v-1.8h3v-5.6c-1.2.8-2 1.2-2.7 1.5zM33.7 38.2c0-.6-.1-.9-.3-1.3-.2-.5-.7-.8-1.4-.8q-1.8 0-1.8 3.3c0 2.1.6 3.1 1.8 3.1 1 0 1.5-.6 1.8-1.9l2.2.1c-.1 1-.3 1.5-.7 2-.7 1-1.9 1.5-3.3 1.5-2.7 0-4.4-2-4.4-5.1 0-1.4.4-2.7 1.1-3.6Q29.9 34 32 34c1.4 0 2.4.5 3.1 1.4.5.7.7 1.2.8 2.4z\"></path></g>", 3)
  ])))
}
export default { render: render }