import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 40 31"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("ellipse", {
      cx: "20",
      cy: "14",
      fill: "#001965",
      rx: "20",
      ry: "14"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#001965",
      d: "m.6 31 13-3.5L4.1 18z"
    }, null, -1)
  ])))
}
export default { render: render }