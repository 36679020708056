import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 80 60"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M.8 0h79.1v60H.8z\" style=\"fill:#005ad2;\"></path><path d=\"m.4 3.2 39.7 35.3L79.9 3.2\" style=\"fill:#005ad2;\"></path><path d=\"m.4 3.2 10.1 8.6 10 8.7 10 8.8 10 8.8h-.7l10-8.8c3.3-2.9 6.6-5.9 10-8.8l10-8.7 10.1-8.6-9.8 9-9.9 8.9c-3.3 3-6.6 6-9.9 8.9l-9.9 8.9-.3.3-.3-.3-9.9-8.9-9.9-8.9-9.8-8.9z\" style=\"fill:#fff;\"></path><path d=\"M50.5 28.9 79.9 60\" style=\"fill:none;\"></path><path d=\"M50.5 28.9c2.5 2.5 5 5.1 7.5 7.6l7.4 7.7 7.4 7.8c2.4 2.6 4.8 5.3 7.2 7.9-2.5-2.5-5-5.1-7.5-7.6L65 44.5l-7.3-7.8q-3.6-3.75-7.2-7.8\" style=\"fill:#fff;\"></path><path d=\"M29.4 28.9 0 60\" style=\"fill:none;\"></path><path d=\"M29.4 28.9c-2.3 2.7-4.7 5.3-7.2 7.9l-7.3 7.8-7.4 7.8C5 54.9 2.5 57.5 0 60c2.3-2.7 4.7-5.3 7.2-7.9l7.3-7.8 7.4-7.7c2.5-2.6 5-5.2 7.5-7.7\" style=\"fill:#fff;\"></path>", 7)
  ])))
}
export default { render: render }