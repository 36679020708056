import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 29 29"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "14.5",
      cy: "14.51",
      r: "14",
      style: {"fill":"#009fda"}
    }, null, -1),
    _createElementVNode("path", {
      d: "m10.71 14.37-.27-.61c1.81-1.32 2.96-2.1 3.45-2.35.63-.31 1.1-.47 1.41-.47.2 0 .37.08.5.23s.2.37.2.65c0 .53-.24 1.62-.73 3.28l-.37 1.21c-.56 1.87-.84 3.05-.84 3.55 0 .25.1.38.31.38q.285 0 1.92-1.08l.3.6-2.32 1.55c-.62.39-1.09.64-1.4.74-.22.08-.42.12-.61.12-.28 0-.5-.09-.66-.29-.15-.19-.23-.5-.23-.93q0-.84.36-2.16l.75-2.67c.38-1.34.57-2.21.57-2.62 0-.19-.09-.29-.26-.29q-.18 0-.54.18c-.57.31-1.09.63-1.54.98m3.59-6.76q0-.855.45-1.35c.45-.495.68-.49 1.14-.49.37 0 .66.11.89.34s.34.53.34.9c0 .52-.15.95-.44 1.29q-.45.51-1.14.51c-.37 0-.67-.11-.9-.34s-.34-.52-.34-.86",
      style: {"fill":"#fff"}
    }, null, -1)
  ])))
}
export default { render: render }