import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "Layer_1",
  "data-name": "Layer 1",
  viewBox: "0 0 44 44"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-2{fill:#fff;stroke:#001965;stroke-miterlimit:10;stroke-width:1.12px}.cls-4{fill:#001965}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<path d=\"M5.82 4.62h33v15h-33z\" style=\"fill:#fff;\"></path><path d=\"m13.15 10.96 1.88 2.49M17.39 8.26l1.5 2.73M32.33 10.96l-1.87 2.49M28.46 8.63l-1.5 2.73\" class=\"cls-2\"></path><path d=\"M23.06 6.92v3.51\" style=\"fill:#fff;stroke:#001965;stroke-miterlimit:10;stroke-width:1.12px;\"></path><path d=\"M22.83 11.68 24 17.45h-2.16z\" class=\"cls-4\"></path><path d=\"M41.18 7.44a4 4 0 0 0-4-4H7.21a4 4 0 0 0-4 4v30a4 4 0 0 0 4 4h29.95a4 4 0 0 0 4-4Zm-9.62 9.28h-18l-3.26-4.3c0-1.06 4.38-6 12.24-6s12.55 4.77 12.55 6Z\" class=\"cls-4\"></path>", 5))
  ]))
}
export default { render: render }