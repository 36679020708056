import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  id: "Layer_1",
  x: "0",
  y: "0",
  version: "1.1",
  viewBox: "0 0 56 58"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(".st1{fill:#fff}")
      ])),
      _: 1
    })),
    _cache[1] || (_cache[1] = _createStaticVNode("<linearGradient id=\"SVGID_1_\" x1=\"5.663\" x2=\"50.337\" y1=\"6.663\" y2=\"51.337\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" style=\"stop-color:#009fda;\"></stop><stop offset=\".125\" style=\"stop-color:#0080bf;\"></stop><stop offset=\".303\" style=\"stop-color:#005b9f;\"></stop><stop offset=\".482\" style=\"stop-color:#003e86;\"></stop><stop offset=\".658\" style=\"stop-color:#002a74;\"></stop><stop offset=\".832\" style=\"stop-color:#001d69;\"></stop><stop offset=\"1\" style=\"stop-color:#001965;\"></stop></linearGradient><path d=\"M51.2 49c0 2-1.6 3.6-3.6 3.6H8.4c-2 0-3.6-1.6-3.6-3.6V9c0-2 1.6-3.6 3.6-3.6h39.2c2 0 3.6 1.6 3.6 3.6z\" style=\"fill:url(#SVGID_1_);\"></path><path d=\"M11.6 26.6s-6.9 9.1 0 9.1c7.2 0 0-9.1 0-9.1m2 8-.6-.5c1.1-1.1.6-2.5.6-2.5 1.1 1.3 0 3 0 3\" class=\"st1\"></path><path d=\"M7.4 37.7h8.1\" style=\"fill:#fff;stroke:#fff;stroke-width:.945;stroke-miterlimit:8;\"></path><path d=\"M29.4 35.6c-.1-1-.9-1.6-1.9-1.5-1.2.1-2.4.3-3.5.5-.9.2-1.6 1-1.5 1.9.1 1 .8 1.6 2 1.6 1-.1 2.3-.3 3.5-.5 1-.2 1.5-1.1 1.4-2M29.6 31.1c0-.9-.4-1.7-1.2-1.9-.4-.2-.8-.2-1.3-.2-1.2.1-2.5.3-3.7.5-.8.1-1.7.2-2.5.4-1 .3-1.5 1.3-1.4 2.4.2 1 1 1.8 2 1.6 2.2-.3 4.3-.5 6.5-.9 1-.1 1.6-.9 1.6-1.9M23.5 24.4c-1.6.2-3.1.4-4.6.7-.3 0-.6.2-.8.4-.7.6-1 1.5-.7 2.4s1 1.4 2.1 1.3c1.5-.2 3-.4 4.6-.6-1.3-1.3-1.3-2.6-.6-4.2\" class=\"st1\"></path><path d=\"M48.8 26.3s-3.9-4.2-9.8-5.9c-6.9-1.9-29.2-.7-29.2-.6-.9.1-2 .9-2 2.4 0 1.1.6 1.7 1.3 1.9.1 0 6.7 2 22.7-2.8.2-.1.4-.1.5-.1.6 0 .3.7.2.8s-.3.1-.5.2c-2.2.7-4.3 1.3-6.5 2-1.2.4-1.8 1.5-1.5 2.6.3 1.2 1.4 1.7 2.6 1.4.7-.2 1.4-.4 2.1-.7q2.25-.9 4.5.3c1.2.6 2.5.9 3.8 1.1.8.1.2.8 0 .8-.4 0-.9 0-1.3-.1l-3.3-1.2c-1.1-.5-2.2-.6-3.3 0 2 1.6 1.9 3.8.2 5.3.7.7 1.2 1.6 1 2.7s-.8 1.8-1.9 2.3c3.1 0 5.6.3 8.8 0 7-1.1 11.6-3.5 11.7-3.9z\" class=\"st1\"></path><path d=\"M8.9 19.9v.3c-.1 0-.1-.3 0-.3\" class=\"st1\"></path>", 7))
  ]))
}
export default { render: render }