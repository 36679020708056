import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 58 58"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<linearGradient id=\"a\" x1=\"5.65\" x2=\"52.391\" y1=\"737.6\" y2=\"784.341\" gradientTransform=\"translate(0 -732)\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" style=\"stop-color:#009fda;\"></stop><stop offset=\".12\" style=\"stop-color:#0080bf;\"></stop><stop offset=\".3\" style=\"stop-color:#005b9f;\"></stop><stop offset=\".48\" style=\"stop-color:#003e86;\"></stop><stop offset=\".66\" style=\"stop-color:#002a74;\"></stop><stop offset=\".83\" style=\"stop-color:#001d69;\"></stop><stop offset=\"1\" style=\"stop-color:#001965;\"></stop></linearGradient><path d=\"M8.2 4.4h41.7c1.8 0 3.3 1.5 3.3 3.3v42.6c0 1.8-1.5 3.3-3.3 3.3H8.2c-1.8 0-3.3-1.5-3.3-3.3V7.7c0-1.8 1.5-3.3 3.3-3.3\" style=\"fill:url(#a);\"></path><path d=\"M30.4 42.3c-2 0-3.9-.3-5.6-.9s-3.2-1.5-4.4-2.6c-1.2-1.2-2.2-2.6-2.8-4.4-.7-1.8-1-3.8-1-6.1 0-2.1.3-4.1 1-5.8.6-1.7 1.6-3.2 2.8-4.5 1.2-1.2 2.6-2.1 4.4-2.8 1.7-.7 3.6-1 5.7-1 1.1 0 2.2.1 3.1.2s1.8.3 2.5.5c.8.2 1.5.5 2.2.8s1.2.5 1.7.8V23h-.8c-.3-.3-.8-.6-1.3-1s-1.1-.8-1.7-1.2c-.7-.4-1.4-.7-2.2-1s-1.6-.4-2.5-.4c-1 0-1.9.2-2.8.5s-1.7.8-2.4 1.5-1.3 1.6-1.7 2.7-.7 2.5-.7 4.1c0 1.7.2 3.1.7 4.2q.75 1.65 1.8 2.7c.7.7 1.6 1.1 2.5 1.4s1.8.4 2.7.4c.8 0 1.7-.1 2.5-.4s1.6-.6 2.3-1c.6-.3 1.1-.7 1.6-1.1s.9-.7 1.3-1h.7V40c-.7.3-1.3.6-1.9.9s-1.3.5-1.9.7c-.9.3-1.7.4-2.5.6-.9.1-1.9.1-3.3.1\" style=\"fill:#fff;\"></path>", 3)
  ])))
}
export default { render: render }