import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  id: "Layer_1",
  x: "0",
  y: "0",
  version: "1.1",
  viewBox: "0 0 46 60"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(".st1{fill:#fff}")
      ])),
      _: 1
    })),
    _cache[1] || (_cache[1] = _createStaticVNode("<linearGradient id=\"SVGID_1_\" x1=\"4.35\" x2=\"41.65\" y1=\"30\" y2=\"30\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" style=\"stop-color:#001965;\"></stop><stop offset=\".155\" style=\"stop-color:#001e69;\"></stop><stop offset=\".332\" style=\"stop-color:#002b75;\"></stop><stop offset=\".52\" style=\"stop-color:#004289;\"></stop><stop offset=\".715\" style=\"stop-color:#0062a5;\"></stop><stop offset=\".914\" style=\"stop-color:#008bc8;\"></stop><stop offset=\"1\" style=\"stop-color:#009fda;\"></stop></linearGradient><path d=\"M22.7 2.5s-41.2 54.9 0 54.9c42.7.1 0-54.9 0-54.9\" style=\"fill:url(#SVGID_1_);\"></path><path d=\"M19.1 44.2h-2.3v-4h-3.1v4h-2.3v-9.8h2.3v3.8h3.1v-3.8h2.3zM24.1 34.4c1.9 0 2.9.4 3.8 1.4.8.9 1.3 2.1 1.3 3.5s-.4 2.6-1.2 3.5c-.8 1-2 1.4-3.4 1.4h-3.4v-9.8zm-.8 7.9h.9c.8 0 1.4-.3 1.8-.8.5-.6.7-1.4.7-2.3s-.3-1.7-.7-2.3-.9-.8-1.8-.8h-1v6.2zM36.8 42.2v1.9h-6.2v-9.8h2.3v7.9z\" class=\"st1\"></path>", 3))
  ]))
}
export default { render: render }