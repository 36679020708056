import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 30 30"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M29.6 14.9C29.6 7 23.2.5 15.2.5S.8 6.9.8 14.9s6.4 14.4 14.4 14.4c7.9 0 14.4-6.5 14.4-14.4",
      style: {"fill":"#fff"}
    }, null, -1),
    _createElementVNode("path", {
      d: "m11.3 14.7-.3-.6c1.9-1.4 3-2.2 3.5-2.4.6-.3 1.1-.5 1.5-.5.2 0 .4.1.5.2.1.2.2.4.2.7 0 .5-.3 1.7-.8 3.4l-.4 1.2q-.9 2.85-.9 3.6c0 .3.1.4.3.4s.9-.4 2-1.1l.3.6-2.2 1.7c-.6.4-1.1.7-1.4.8-.2.1-.4.1-.6.1-.3 0-.5-.1-.7-.3s-.2-.5-.2-1c0-.6.1-1.3.4-2.2l.8-2.7q.6-2.1.6-2.7 0-.3-.3-.3c-.1 0-.3.1-.6.2-.7.2-1.2.6-1.7.9M15 7.8c0-.6.2-1 .5-1.4.3-.3.7-.5 1.2-.5.4 0 .7.1.9.4.2.2.4.5.4.9 0 .5-.2 1-.5 1.3-.4.3-.8.5-1.2.5s-.7-.1-.9-.4c-.3-.2-.4-.5-.4-.8",
      style: {"fill":"#009fda"}
    }, null, -1)
  ])))
}
export default { render: render }