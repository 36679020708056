import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 58 58"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<linearGradient id=\"a\" x1=\"5.65\" x2=\"52.391\" y1=\"737.6\" y2=\"784.341\" gradientTransform=\"translate(0 -732)\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" style=\"stop-color:#009fda;\"></stop><stop offset=\".12\" style=\"stop-color:#0080bf;\"></stop><stop offset=\".3\" style=\"stop-color:#005b9f;\"></stop><stop offset=\".48\" style=\"stop-color:#003e86;\"></stop><stop offset=\".66\" style=\"stop-color:#002a74;\"></stop><stop offset=\".83\" style=\"stop-color:#001d69;\"></stop><stop offset=\"1\" style=\"stop-color:#001965;\"></stop></linearGradient><path d=\"M8.2 4.4h41.7c1.8 0 3.3 1.5 3.3 3.3v42.6c0 1.8-1.5 3.3-3.3 3.3H8.2c-1.8 0-3.3-1.5-3.3-3.3V7.7c0-1.8 1.5-3.3 3.3-3.3\" style=\"fill:url(#a);\"></path><path d=\"M41.3 33.5c0 1.3-.3 2.5-.8 3.5s-1.2 1.9-2.2 2.6c-1.1.8-2.2 1.4-3.5 1.7s-2.9.5-4.8.5H18.2v-27h10.5c2.2 0 3.8.1 4.8.2s2 .5 3 1 1.8 1.2 2.3 2.1.8 1.9.8 3c0 1.3-.3 2.5-1 3.5s-1.7 1.8-2.9 2.3v.1c1.8.4 3.2 1.1 4.2 2.2s1.4 2.5 1.4 4.3m-8.9-11.1c0-.4-.1-.9-.3-1.3s-.6-.8-1.2-1c-.5-.2-1.1-.3-1.9-.3h-3.9v5.7h1.1c1.1 0 2 0 2.8-.1s1.4-.2 1.8-.4c.6-.3 1-.6 1.2-1.1.3-.4.4-.9.4-1.5m1.6 11c0-.9-.2-1.5-.5-2s-.9-.8-1.7-1c-.5-.2-1.3-.2-2.2-.3h-4.5v6.7h4.7c.8 0 1.6-.2 2.3-.5q1.05-.45 1.5-1.2c.3-.5.4-1.1.4-1.7\" style=\"fill:#fff;\"></path>", 3)
  ])))
}
export default { render: render }