import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 28 25"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M4.47.62h23v20c0 2.21-1.79 4-4 4h-23v-20c0-2.21 1.8-4.01 4-4",
      style: {"fill":"#fff"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M18.88 8.78c.01.53-.08 1.05-.26 1.55-.16.43-.4.83-.72 1.17-.34.34-.72.64-1.13.88-.47.28-.97.52-1.48.72v2h-3.06v-2.91c.41-.11.79-.22 1.12-.34.38-.15.75-.36 1.07-.61.32-.21.59-.48.8-.8.19-.32.29-.68.29-1.05.02-.47-.19-.92-.58-1.2-.48-.27-1.03-.4-1.58-.37-.48.01-.96.1-1.41.27-.51.17-.99.41-1.43.7h-.35V6.11c.55-.21 1.13-.38 1.71-.49.77-.17 1.55-.26 2.34-.26 1.21-.06 2.41.27 3.41.95.8.57 1.27 1.49 1.26 2.47M12.37 17.53h2.81v2.22h-2.81z",
      style: {"fill":"#005ad2"}
    }, null, -1)
  ])))
}
export default { render: render }