import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 1",
  viewBox: "0 0 70 64"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<text style=\"opacity:.35;font-size:68.57px;font-family:Verdana-Bold,Verdana;font-weight:700;fill:#001965;\" transform=\"translate(21.62 56.21)\">!</text><path d=\"m6.1 55.69 23.51-32.33 10.82 16.66 16.46-23.37\" style=\"fill:none;stroke:#001965;stroke-linecap:round;stroke-linejoin:round;\"></path><path d=\"m59.4 27.43-3.07-9.98-10.43.46 18-11.2z\" style=\"fill:#001965;\"></path><path d=\"m59.29 24.45 3.48-16.14-5.88 8.34z\" style=\"fill:#6675a3;\"></path><path d=\"m48.73 17.06 14.04-8.75-5.89 8.36z\" style=\"fill:#a6afc9;\"></path>", 5)
  ])))
}
export default { render: render }