import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 58 58"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<linearGradient id=\"a\" x1=\"4.643\" x2=\"51.381\" y1=\"738.003\" y2=\"784.74\" gradientTransform=\"translate(.07 -732.324)\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" style=\"stop-color:#009fda;\"></stop><stop offset=\".12\" style=\"stop-color:#0080bf;\"></stop><stop offset=\".3\" style=\"stop-color:#005b9f;\"></stop><stop offset=\".48\" style=\"stop-color:#003e86;\"></stop><stop offset=\".66\" style=\"stop-color:#002a74;\"></stop><stop offset=\".83\" style=\"stop-color:#001d69;\"></stop><stop offset=\"1\" style=\"stop-color:#001965;\"></stop></linearGradient><path d=\"m8.1 4.5 41.7-.1c1.8 0 3.3 1.5 3.3 3.2l.1 42.6c0 1.8-1.5 3.3-3.2 3.3l-41.8.1c-1.8 0-3.3-1.5-3.3-3.2V7.7c0-1.8 1.5-3.2 3.2-3.2\" style=\"fill:url(#a);\"></path><path d=\"M42.9 41.7h-7.2l-1.9-5.5h-10l-1.9 5.5h-7l9.9-27h8zM32.1 31.3l-3.3-9.7-3.3 9.7z\" style=\"fill:#fff;\"></path>", 3)
  ])))
}
export default { render: render }