
/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory } from 'vue-router/auto'
import { useStore } from 'vuex'

const routes = [
  { path: '/', component: () => import('../pages/index.vue') },
  { path: '/index', component: () => import('../pages/index.vue') },
  { path: '/about', component: () => import('../pages/about.vue') },
  { path: '/email', meta: {requiresData: true}, component: () => import('../pages/email.vue') },
  { path: '/goals', meta: {requiresData: true}, component: () => import('../pages/goals.vue') },
  { path: '/inputs', meta: {requiresData: true}, component: () => import('../pages/inputs.vue') },
  { path: '/intendeduse', component: () => import('../pages/intendeduse.vue') },
  { path: '/lifestyle', meta: {requiresData: true}, component: () => import('../pages/lifestyle.vue') },
  { path: '/moreinfo', component: () => import('../pages/moreinfo.vue') },
  { path: '/questionnaire', component: () => import('../pages/questionnaire.vue') },
  { path: '/references', component: () => import('../pages/references.vue') },
  { path: '/summary', meta: {requiresData: true}, component: () => import('../pages/summary.vue') },
  // { path: '/:pathMatch(.*)*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
})

router.beforeEach((to, from, next) => {
  const store = useStore()
  const userData = store.getters.userData
  const completedQuestionnaire = store.getters['userData/completedQuestionnaire']

  if (to.matched.some(record => record.meta.requiresData)) {
    console.log(store)
    console.log(userData)
    console.log(completedQuestionnaire)
    // console.log(store.getters)

    // Check if user completed the questionnaire
    if (!completedQuestionnaire) {
      next({ path: '/' })
    } else {
      next() // continue to the next page
    }
  } else {
    next() // continue to the next page
  }
})

// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to) => {
  if (err?.message?.includes?.('Failed to fetch dynamically imported module')) {
    if (!localStorage.getItem('vuetify:dynamic-reload')) {
      console.log('Reloading page to fix dynamic import error')
      localStorage.setItem('vuetify:dynamic-reload', 'true')
      location.assign(to.fullPath)
    } else {
      console.error('Dynamic import error, reloading page did not fix it', err)
    }
  } else {
    console.error(err)
  }
})

router.isReady().then(() => {
  localStorage.removeItem('vuetify:dynamic-reload')
})

export default router
