import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "Layer_1",
  "data-name": "Layer 1",
  viewBox: "0 0 80 70"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-4{fill:#fff}.cls-2{opacity:.5}.cls-3{fill:#009fda}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<path d=\"M45 5a30.78 30.78 0 1 0 .06 0\" style=\"fill:#fff;stroke:#009fda;stroke-miterlimit:10;stroke-opacity:.5;\"></path><g class=\"cls-2\"><path d=\"M45.37 10.28a24.48 24.48 0 1 0 .14 0\" class=\"cls-3\"></path></g><path d=\"M45.37 15.38a19.5 19.5 0 1 0 .07 0\" class=\"cls-4\"></path><g class=\"cls-2\"><path d=\"M45.36 21a14 14 0 1 0 .06 0\" class=\"cls-3\"></path></g><path d=\"M45.7 25.62a9.39 9.39 0 1 0 9 9.79 9.36 9.36 0 0 0-9-9.78\" class=\"cls-4\"></path><path d=\"M45 30a5 5 0 1 0 3.65 1.24A5 5 0 0 0 45 30M10.69 6.57l-.44 8-5.6 3.64 9 5.95 6.33-4L20.6 12l-9.91-5.45\" class=\"cls-3\"></path><path d=\"m8.03 18.19 37.5 22.4\" style=\"fill:none;stroke:#001965;stroke-linecap:round;stroke-linejoin:round;\"></path>", 7))
  ]))
}
export default { render: render }