import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "Layer_1",
  "data-name": "Layer 1",
  viewBox: "0 0 56 72"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-5{fill:none;stroke:#3f9c35;stroke-miterlimit:10;stroke-opacity:.25}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<path d=\"M7.2 12.73h41.6v53.26H7.2z\" style=\"fill:#fff;\"></path><path d=\"M7.2 12.73h41.6v53.26H7.2z\" style=\"fill:none;stroke:#3f9c35;stroke-miterlimit:10;stroke-width:.5px;\"></path><path d=\"M17.23 13.8a1.8 1.8 0 0 0 1.3.47h18.81a1.55 1.55 0 0 0 1.3-2.61 1.87 1.87 0 0 0-1.3-.64l-4.34-.4a1.56 1.56 0 0 1-1.45-1.43C31.56 7.09 31 6 29 6h-2.16c-2 0-2.54 1.1-2.54 3.2a1.57 1.57 0 0 1-1.44 1.43l-4.33.37a1.7 1.7 0 0 0-1.3 2.8M28 7.3a1.23 1.23 0 1 1-1.23 1.22A1.21 1.21 0 0 1 28 7.3\" style=\"fill:#3f9c35;\"></path><path d=\"M29.81 19.25h-3.94v4.12h-3.94v3.94h3.94v3.94h3.94v-3.94h3.94v-3.94h-3.94z\" style=\"fill:#3f9c35;opacity:.5;\"></path><path d=\"M12.84 44.55h30M12.84 36.99h30M12.84 52.11h30M12.84 55.9h30M12.84 40.77h30M12.84 59.68h30M12.84 48.33h30\" class=\"cls-5\"></path>", 5))
  ]))
}
export default { render: render }