import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 11 18.5"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M10.8 1V.2L.1.3v.8h4.8L5 17.4l-4.8.1v.8l10.7-.1v-.8H6.1L6 1.1z" }, null, -1)
  ])))
}
export default { render: render }