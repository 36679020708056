import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 1",
  viewBox: "0 0 38 38"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("text", {
      style: {"font-size":"40px","fill":"#001965","font-family":"Verdana-Bold,Verdana","font-weight":"700"},
      transform: "translate(3.73 33.21)"
    }, "X", -1)
  ])))
}
export default { render: render }