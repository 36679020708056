import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "Layer_1",
  "data-name": "Layer 1",
  viewBox: "0 0 54 54"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      _cache[1] || (_cache[1] = _createStaticVNode("<linearGradient id=\"linear-gradient\" x1=\"3.5\" x2=\"50.5\" y1=\"27\" y2=\"27\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" stop-color=\"#001965\"></stop><stop offset=\".16\" stop-color=\"#001e69\"></stop><stop offset=\".33\" stop-color=\"#002b75\"></stop><stop offset=\".52\" stop-color=\"#004289\"></stop><stop offset=\".71\" stop-color=\"#0062a5\"></stop><stop offset=\".91\" stop-color=\"#008bc9\"></stop><stop offset=\"1\" stop-color=\"#009fda\"></stop></linearGradient>", 1)),
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-2{fill:#fff}")
        ])),
        _: 1
      }))
    ]),
    _cache[2] || (_cache[2] = _createStaticVNode("<circle cx=\"27\" cy=\"27\" r=\"23.5\" style=\"fill:url(#linear-gradient);\"></circle><g id=\"smoke\"><g id=\"g6\"><path id=\"rect8\" d=\"m17.73 31.52 24.42-12.25 1.97 4.13L19.7 35.66z\" class=\"cls-2\"></path><path id=\"rect10\" d=\"m15.86 32.38.91-.45 1.98 4.13-.91.46z\" class=\"cls-2\"></path><path id=\"rect12\" d=\"m13.97 33.39.91-.46 1.97 4.14-.9.45z\" class=\"cls-2\"></path><path id=\"path14\" d=\"m16.33 31.09-.92.37a4.64 4.64 0 0 1-.85-4.85c.9-1.88 3.12-1.71 4.48-5 1.19-2.89-1.7-5.93-1.7-5.93L18 15a6.93 6.93 0 0 1 2.06 5.77c-.65 4.13-4.06 4.23-4.77 6.5a3.72 3.72 0 0 0 1 3.82Z\" class=\"cls-2\"></path><path id=\"path16\" d=\"m14.22 32.05-.81.45S11 29.41 12.45 26s3.55-2.82 4.38-5.1a3.12 3.12 0 0 0-1-3.61l.68-.62a4.06 4.06 0 0 1 1.2 4.51c-1 2.71-3 1.95-4.37 5.14a5.57 5.57 0 0 0 .88 5.73\" class=\"cls-2\"></path><path id=\"path18\" d=\"m20.62 23.19 1.17-.54a9.36 9.36 0 0 0-.08-6.21 11 11 0 0 0-3.77-4.51l-.6.73a8.05 8.05 0 0 1 3.35 3.83 7.91 7.91 0 0 1-.07 6.7\" class=\"cls-2\"></path></g></g>", 2))
  ]))
}
export default { render: render }