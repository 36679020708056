/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { mdi } from 'vuetify/iconsets/mdi'

// Composables
import { createVuetify } from "vuetify"
import { novoIcons } from './icons'

const novoTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    primary: '#005AD2',
    secondary: '',
    error: '#E6553F',
    info: '',
    success: '',
    warning: '',

  }
}



// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'novoTheme',
    themes: {
      novoTheme,
    },
  },
  breakpoint: {
    thresholds: {
       xs: 0,
       sm: 320,
       md: 768,
       lg: 1024,
       xl: 1440,
       xxl: 1600,
    }
  },
  display: {
    thresholds: {
      xs: 0,
       sm: 320,
       md: 768,
       lg: 1024,
       xl: 1440,
       xxl: 1600,
    }
  },
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi,
      novo: novoIcons,
    }
  },

  defaults: {
    VBtn: {
      color: 'primary',
      variant: 'flat',
    },
    VTextField: {
      color: 'primary',
      variant: 'outlined',
    },
    VRadioGroup: {
      color: '#005AD2',
      'base-color': '#D4D7DC',
    },
    VCheckbox: {
      color: '#005AD2',
      'base-color': '#D4D7DC',
    },
  }
})
