import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 56 58"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<linearGradient id=\"a\" x1=\"5.663\" x2=\"50.337\" y1=\"6.663\" y2=\"51.337\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" style=\"stop-color:#009fda;\"></stop><stop offset=\".125\" style=\"stop-color:#0080bf;\"></stop><stop offset=\".303\" style=\"stop-color:#005b9f;\"></stop><stop offset=\".482\" style=\"stop-color:#003e86;\"></stop><stop offset=\".658\" style=\"stop-color:#002a74;\"></stop><stop offset=\".832\" style=\"stop-color:#001d69;\"></stop><stop offset=\"1\" style=\"stop-color:#001965;\"></stop></linearGradient><path d=\"M51.2 49c0 2-1.6 3.6-3.6 3.6H8.4c-2 0-3.6-1.6-3.6-3.6V9c0-2 1.6-3.6 3.6-3.6h39.2c2 0 3.6 1.6 3.6 3.6z\" style=\"fill:url(#a);\"></path><path d=\"M27.4 14s-21.3 28.9 0 28.9c23.4-.1 0-28.9 0-28.9m4.5 16.8h-1.4c-.6 0-1 .1-1.1.2s-.2.5-.2 1.1v1.4c0 1 .1 1.4-1.2 1.4s-1.2-.4-1.2-1.4v-1.4c0-.6.1-1 0-1.1s-.4-.2-1-.2h-1.4c-1 0-1.4.1-1.4-1.2s.4-1.2 1.4-1.2h1.4c.6 0 .8.1 1 0 .1-.1 0-.3 0-.9V26c0-1-.1-1.4 1.2-1.4s1.2.4 1.2 1.4v1.4c0 .6.1.8.2.9s.5 0 1.1 0h1.4c1 0 1.4-.1 1.4 1.2s-.5 1.3-1.4 1.3m2.2 8.7-.9-1.1c3.4-3.6.9-8.2.9-8.2 3.3 4 0 9.3 0 9.3\" style=\"fill:#fff;\"></path>", 3)
  ])))
}
export default { render: render }